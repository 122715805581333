import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container, Row, Col } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    
    <Container className="my-4">
      <h1>Contact Us!</h1>
      <Row className="g-5">
        <Col lg={6}>
          <p>We would be thrilled to work with you on your new website. Feel free to use the contact form here or email or call us.</p>
          <p>Phone: +1 (415) 999-3378</p>
          <p>Email: <a href="mailto:haydn@nautilusdesigns.com">haydn@nautilusdesigns.com</a></p>
        </Col>
        <Col lg={6}>
          <p>Thanks! We'll get back to you very soon!</p>
          </Col>
        </Row>
      </Container>
    </Layout>
)

export default SecondPage
